import { styled } from '@linaria/react';

import { ColorsTokens } from '@/styles/theme/colorTokens';

export const PickerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
`;

export const PickerItem = styled.div<{ backgroundColor: keyof typeof ColorsTokens }>`
  position: relative;
  background-color: ${({ backgroundColor }) => `var(--${backgroundColor}`};
  height: 3.25rem;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  border-radius: var(--radius-round);
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--border-strong);
  padding: var(--spacing-sm) var(--spacing-md);

  svg {
    color: var(--primary);
  }

  &:not([data-disabled='true']):hover {
    box-shadow: var(--elevation-2);
  }

  &[data-selected='true'] {
    padding: calc(var(--spacing-sm) - 1px) calc(var(--spacing-md) - 1px);
    border: 2px solid var(--primary);
  }

  &[data-disabled='true'] {
    opacity: 0.6;
    border: 1px solid var(--border-weak);
  }

  &:not([data-disabled='true']) {
    cursor: pointer;
  }

  &[data-error='true'] {
    border: 1px solid var(--danger);
  }
`;
